<template>
  <div class="success">
    <div class="tp" v-if='type==1'>
      <img src="../../assets/cgtb.png" alt="">
    </div>
    <div class="tps" v-if='type==0'>
      <img src="../../assets/ptcg.png" alt="">
    </div>
    <p v-if='type==1'>报名成功</p>
    <p v-if='type==0'>参团成功</p>
    <div class="btn" @click='download'>
      查看课程
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type:''
    };
  },
  mounted(){
    let currentQuery = this.$route.query;
    this.type=currentQuery.type
    this.$nextTick(() => {

    });
  },
  methods:{
    download() {
        this.$router.push({ path:'/download'})
    }
  }
};
</script>
<style   lang="less" scoped>
.tp {
  width: 3.19rem;
  height: 4.75rem;
  margin-top:1.17rem;
  margin-left: 3.41rem;

  img {
    width: 100%;
    height: 100%;
  }
}
.tps {
  width: 3.83rem;
  height:4.05rem;
  margin-top:1.17rem;
  margin-left: 3.41rem;

  img {
    width: 100%;
    height: 100%;
  }
}
p {
  width: 100%;
  text-align: center;
  font-size: .4rem;
  margin:.63rem 0 0 0;
  color: #2B2B2B;
  font-weight: 600;
}
.btn {
  width: 4.24rem;
  height: 1.76rem;
  background: url(../../assets/cgbtn.png);
  background-size: 100% 100% ;
  margin-top:.91rem;
  margin-left: 2.92rem;
  color: #fff;
  font-size: .48rem;
  line-height: 1.76rem;
  text-align: center;
}

</style>